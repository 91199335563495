export default {
  /**
   * Map this userData to calculate other needed data
   *
   * @param {Object} userData
   * @returns {Object}
   */
  computeTotalValues: (userData) => {
    return {
      likes: userData.data?.user_profile?.avg_likes || 0,
      followers: userData.data?.user_profile?.followers || 0,
      comments: userData.data?.user_profile?.avg_comments || userData.data?.user_profile?.engagements || 0
    }
  },

  /**
   * Map this userData to calculate overviewData values
   *
   * @param {Object} userData
   * @returns {Object}
   */
  computeOverviewData(userData) {
    const averageReelValues = (propName) => {
      return (userData.data.user_profile.recent_reels.reduce((pre, curr) => pre + (curr.stat[propName] || 0), 0) / userData.data.user_profile.recent_reels.length)
    }

    return {
      followers: !userData.data.user_profile.followers ? null : nFormatter(userData.data.user_profile.followers),
      postsCount: !userData.data.user_profile.posts_count ? null : nFormatter(userData.data.user_profile.posts_count),
      views: !userData.data.user_profile.avg_views ? null : nFormatter(userData.data.user_profile.avg_views),
      likes: !userData.data.user_profile.avg_likes ? null : nFormatter(userData.data.user_profile.avg_likes),
      dislikes: !userData.data.user_profile.avg_dislikes ? null : nFormatter(userData.data.user_profile.avg_dislikes),
      comments: !userData.data.user_profile.avg_comments ? null : nFormatter(userData.data.user_profile.avg_comments),
      engagementRate: !userData.data.user_profile.engagement_rate
        ? null
        : (userData.data.user_profile.engagement_rate * 100).toFixed(2),

      reelsViews: !userData.data.user_profile.avg_reels_plays ? null : nFormatter(userData.data.user_profile.avg_reels_plays),
      reelsLikes: !userData.data.user_profile.recent_reels ? null : nFormatter(averageReelValues("likes")),
      reelsComments: !userData.data.user_profile.recent_reels ? null : nFormatter(averageReelValues("comments")),
      reelsEngagementRate: !(userData.data.user_profile.avg_reels_plays && userData.data.user_profile.followers) ? null : (userData.data.user_profile.avg_reels_plays / userData.data.user_profile.followers).toFixed(2),

      // If paid_post_performance value exists, get the value as comparison to what the normal engagement rate is
      // For example if paid_post_performance is 1.29 and the engagement_rate is 0.0439
      // then the paidPostPerformance value that we want to show is 129% of 0.0439 which is 5.67%
      paidPostPerformance: ["NaN", null, undefined].includes(userData.data.user_profile.paid_post_performance)
        ? null
        : (userData.data.user_profile.paid_post_performance * userData.data.user_profile.engagement_rate * 100).toFixed(2),

      // paidPostPerformance: ["NaN", null, undefined].includes(userData.data.user_profile.paid_post_performance)
      //   ? null
      //   : (userData.data.user_profile.paid_post_performance * 100).toFixed(2),
      location:
        userData.data.user_profile?.geo?.city?.name
        || userData.data.user_profile?.geo?.country?.name,
      locationFlag: userData.data.user_profile?.geo?.country?.code,
    }
  },

  /**
   * Map the userData to calculate engagement data values
   *
   * @param {Object} userData
   * @returns {Object}
   */
  computeEngagementData: (userData) => {
    const getRangeLabel = (min = -Infinity, max = Infinity, formatter = (v) => `${v}`) => {
      if (max === Infinity) {
        return ` > ${formatter(min)} `
      }

      if (min === -Infinity) {
        return `< ${formatter(max)}`
      }

      return `${formatter(min)}-${formatter(max)}`
    }

    const newData = {
      comments: [],
      likes: [],
      rate: [],
      hashtags: userData.data.user_profile?.top_hashtags?.map((ht) => ({
        tag: ht.tag,
        weight: ht.weight * 100,
      })),
      mentions: userData.data.user_profile?.top_mentions?.map((tm) => ({
        tag: tm.tag,
        weight: tm.weight * 100,
      })),
    }

    userData.data.extra?.engagement_rate_histogram
      ?.sort((b, a) => (a.max ?? Infinity) - (b.max ?? Infinity))
      .forEach((er) => {
        const curr = userData.data.user_profile?.engagement_rate ?? 0;
        newData.rate.push({
          // x: (er.max ?? 0) * 100,
          y: er.total,
          user: curr < (er.max || 100) && curr > (er.min || 0),
          x: getRangeLabel(er.min, er.max, (v) => numeral.fPercent(v * 100)),
          description: `${numeral.fPercent(er.min * 100)} - ${numeral.fPercent(er.max * 100)}`,
          median: er.median,
        })
      })

    userData.data.user_profile?.recent_posts?.forEach((p) => {
      const date = new Date(p.created).getTime()

      newData.comments.push({
        x: date,
        y: p.stat.comments,
        postUrl: p?.link,
      })

      newData.likes.push({
        x: date,
        y: p.stat.likes,
        postUrl: p?.link,
      })
    })

    return newData
  },

  /**
   * Map the userData to calculate audience data values
   *
   * @param {Object} userData
   * @returns {Object}
   */
  computeAudienceData: (userData) => {
    const getRangeLabel = (min = -Infinity, max = Infinity, formatter = (v) => `${v}`) => {
      if (max === Infinity) {
        return ` > ${formatter(min)} `;
      }

      if (min === -Infinity) {
        return `< ${formatter(max)}`;
      }

      return `${formatter(min)}-${formatter(max)}`;
    }

    const audienceDataByType = (data, audienceType) => {
      const COUNTRY = []

      data?.notable_users?.forEach((el) => {
        COUNTRY.push(el?.geo?.country)
      })

      const newData = {
        audience: audienceType,
        audienceBrandAffinity: data?.audience_brand_affinity?.map((d) => d),
        country:
          data?.audience_geo?.countries?.map((c) => ({
            x: c.name,
            y: c.weight * 100,
            countryCode: c.code
          })) ?? [],
        state:
          data?.audience_geo?.states?.map((c) => ({
            x: c.name,
            y: c.weight * 100,
            countryCode: c.country.code
          })) ?? [],
        city:
          data?.audience_geo?.cities?.map((c) => ({
            x: c.name,
            y: c.weight * 100,
            countryCode: c.country.code
          })) ?? [],
        geo: COUNTRY ?? [],
        cityGeo: data?.audience_geo?.cities ?? [],
        age: {
          male: [],
          female: [],
        },
        commonAge: [],
        ethnicity:
          data?.audience_ethnicities?.map((c) => ({
            x: c.name,
            y: c.weight * 100,
          })) ?? [],
        language:
          data?.audience_languages?.map((c) => ({
            x: c?.name ?? capitalizeString(c?.code),
            y: c?.weight * 100,
          })) ?? [],
        reachability:
          data?.audience_reachability?.map((c) => {
            const [min, max] = c.code.split("-");
            return {
              x: getRangeLabel(min ?? -Infinity, max ?? Infinity),
              y: c.weight * 100,
            };
          }) ?? [],
        interests:
          data?.audience_interests?.map((c) => ({
            label: c?.name,
            weight: c?.weight * 100,
            affinity: c?.affinity,
          })) ?? [],
        brands:
          data?.audience_brand_affinity?.map((c) => ({
            iconUrl: c.logo,
            label: c.name,
            weight: c.weight * 100,
            affinity: c.affinity,
          })) ?? [],
        notable:
          data?.notable_users?.map((u) => ({
            imageUrl: u.picture,
            influencerId: u.username,
            url: u.url,
            displayName: u.fullname,
            followers: u.followers,
            engagements: u.engagements,
          })) ?? [],
        lookalike:
          data?.audience_lookalikes?.map((u) => ({
            imageUrl: u.picture,
            influencerId: u.username,
            url: u.url,
            displayName: u.fullname,
            followers: u.followers,
            engagements: u.engagements,
            locationLabel: [u?.geo?.city?.name, u?.geo?.country?.name]
              .filter((a) => !!a)
              .join(", "),
          })) ?? [],

        // to make sure male comes before female does
        gender: data?.audience_genders.sort((a, b) => b.code[0].localeCompare(a.code[0])) ?? [],
      }

      data?.audience_genders_per_age?.forEach((c) => {
        newData.commonAge.push({
          x: c?.code,
          y: (c?.male + c?.female) * 100,
          count: { male: c?.male * 100, female: c?.female * 100 },
        })

        newData.age.male.push({
          x: c?.code,
          y: c?.male * 100,
        })

        newData.age.female.push({
          x: c?.code,
          y: c?.female * 100,
        })
      })

      newData.audienceTypes = data?.audience_types || null

      // The key to be used in extraData
      const map = {
        "LIKES": "audience_credibility_likers_histogram",
        "FOLLOWERS": "audience_credibility_followers_histogram",
      }

      newData.credibilityGraph = []
      newData.credibilityScore = data?.audience_credibility * 100

      map[audienceType] && (userData.data?.extra?.[ map[audienceType] ]
        ?.sort((b, a) => (a.max ?? Infinity) - (b.max ?? Infinity))
        .forEach((er) => {
          const curr = (newData.credibilityScore ?? 0) / 100
          newData.credibilityGraph.push({
            // x: (er.max ?? 0) * 100,
            y: er.total,
            user: curr < (er.max || 100) && curr > (er.min || 0),
            x: getRangeLabel(er.min, er.max, (v) => numeral.fPercent(v * 100)),
            description: `${numeral.fPercent(er.min * 100)} - ${numeral.fPercent(er.max * 100)}`,
            median: er.median,
          })
        })
      )

      return newData
    }

    return {
      likes: audienceDataByType(
        userData.data?.audience_likers?.data,
        "LIKES"
      ),
      comments: audienceDataByType(
        userData.data?.audience_commenters?.data,
        "COMMENTERS"
      ),
      followers: audienceDataByType(
        userData.data?.audience_followers?.data,
        "FOLLOWERS"
      ),
      extraData: {
        followersCreds: userData.data?.audience_followers?.data?.audience_credibility * 100,
        notableFollowers: userData.data?.audience_followers?.data?.notable_users_ratio * 100,
        likerCreds: userData.data?.audience_likers?.data?.audience_credibility * 100,
        notableLikes: userData.data?.audience_likers?.data?.notable_users_ratio * 100,
        nonFollowerLikes: userData.data?.audience_likers?.data?.likes_not_from_followers * 100,
        isLikersDataAvailable: userData.data?.audience_likers?.success,
        isFollowersDataAvailable: userData.data?.audience_followers?.success,
        isCommentersDataAvailable: userData.data?.audience_commenters?.success
      },
    }
  },

  /**
   * Map the userData value to show the correct error message
   * computedErrorMessage
   *
   * @param {Object} userData
   * @returns {Object}
   */
  computeErrorMessage: (userData) => {
    const laterMessage = "We are updating data for this account. Try again in 15 minutes, please."
    const messagesMap = {
      account_not_found: "Invalid URL or @mention (user could have changed it)",
      invalid_url: "Invalid URL, please recheck",
      empty_audience_data: "No influencer insight found",
      insufficient_balance: "You don't have enough credits",
      no_access: "You don't have access to this report",
      will_be_available_later: laterMessage,
      retry_later: laterMessage,
    }

    return messagesMap[userData?.data?.error] || "An error occurred."
  }
}
